<template>
    <el-container style="height: 100vh ;flex-direction: column">
      <div class="contract-edit-header" v-loading="exportLoading">
        <div style="display: flex" id="contract-edit-header-left">
          <ExitBtn :handler="backToIndex"/>
          <div style="text-align: left;padding-left:20px">
            <edit-title ref="editTitle"
                        v-model:title="oldTitle"
                        v-model:mode="titleEditMode"
                        :max-length="20"
                        :upload-fn="changeTitle"></edit-title>
            <div id="topBar-left-toolBar" >
              <div style="padding-right: 15px;border-right: solid 1px #DDD">
                <div>
                  <span class="stage-circle" :class="getTemplateStatusClass()"></span><span v-html="getTemplateStatusText()"></span>
                </div>

              </div>
            <ul class="contract-menu" @click="menuActive=true" @mouseleave="menuActive=false">
              <li class="contract-menu-item" @click="FileMenuVisible=!FileMenuVisible" @mouseleave="FileMenuVisible=false" @mouseenter="FileBtnHover">
                <span>文件</span>
                <ul class="dropdown-menu" v-show="FileMenuVisible">
                  <li class="dropdown-menu-item" @click="titleEditMode=true"><i></i><span>重命名</span></li>
                  <div style="border-bottom: solid 1px #DDD;margin: 10px 0"></div>
                  <li class="dropdown-menu-item" :class="{unselectable:!(templateId&& userInfo && checkUserBehaviorPermission(userInfo.mainSiteTeamId,BehaviorPermission.TEMPLATE_APPROVAL))}" @click="askForDelete"><i class="el-icon-delete"></i><span>删除</span></li>
                </ul>
              </li>
              <li class="contract-menu-item"
                  @click="onSettingMenuClicked"
                  @mouseleave="settingMenuVisible = false"
              >
                <span>设置</span>
                <ul class="dropdown-menu" v-show="settingMenuVisible">
                  <li
                      class="dropdown-menu-item"
                      @click="onTemplateTipClicked"
                  >
                    <i class="el-icon-info"></i><span>模板提示</span>
                  </li>
                </ul>
              </li>
              <HelpListItem
                  data-tag-id="contract-docTemplate-help"
                  @click="helpMenuVisible = !helpMenuVisible"
                  @mouseenter="helpBtnHover"
                  @mouseleave="helpMenuVisible = false"
              ></HelpListItem>
            </ul>
          </div>
          <!--          <div class="buttonGroup">-->


          <!--            <el-button  v-if="canWrite && contract.approvalStatus!=ContractApprovalStatus.LAUNCH_READY_TO_SIGN_PASSED && contract.approvalStatus!=ContractApprovalStatus.LAUNCH_SIGNED_PASSED" size="small" @click="editContract">编辑</el-button>-->

          <!--            <el-button size="small" @click="needUpload=!needUpload;targetStatus=0">上传新版本</el-button>-->
          <!--&lt;!&ndash;            <el-button size="small" @click="watchHistory">历史版本</el-button>&ndash;&gt;-->
          <!--            <el-button size="small" @click="showAttachmentDialog()"> 附件</el-button>-->
          <!--            <el-button size="small" @click="diffWithLastVersion" v-if="versions.length>=2">和上版本diff</el-button>-->
          <!--          </div>-->

          <!--      <div style="text-align:left;margin-left: 20px;line-height: 50px;">-->

          <!--        <el-tag v-for="(item,index) in tags" :key="index" style="margin-right: 10px">{{ item.name }}</el-tag>-->

          <!--      </div>-->
          <!--          <approval-process-control ref="processControl" :contractId="contract.id" :onLaunchApprovalProcess="onLaunchApprovalProcess" :onHandleApprovalProcess="onApprovalStatusChanged" :contract="contract"></approval-process-control>-->
        </div>
      </div>

      <div id="contract-edit-header-center">
        <el-radio-group class="contract-switch-mode" size="small" v-model="currentMode" @change="modeChange">
          <el-radio-button
              :key="item.value"
              v-for="item in modes"
              :label="item.value">
            {{item.label}}
          </el-radio-button>
        </el-radio-group>
      </div>
      <div id="contract-edit-header-right">
        <span class="publish-button " @click="createContract" v-if="draftContractMode">完成编辑</span>
        <span class="publish-button " @click="publish" v-else-if="publishedTemplateId">保存</span>
        <span class="publish-button " @click="publish" v-else>完成编辑</span>
<!--        <span class="publish-button color-blue" @click="shareAnswerSheet" v-if="isSmarter">分享</span>-->
      </div>
      </div>
    <!--      <el-header style="position: fixed;width: 100%;z-index: 100;background: white;height: 50px;">-->
    <!--        <Header>-->
    <!--          <template #left>-->
    <!--            <el-button-group :style="{ marginLeft: windowWidth*0.5 - 70 + 'px' }">-->
    <!--              <el-button id="editTabButton"  :class="{ 'modeChecked': activeMode === 'edit_model'}" size="medium" @click="modeChange('edit_model')">编辑</el-button>-->
    <!--              <el-button id="previewTabButton" :class="{ 'modeChecked': activeMode === 'answer_model'}" size="medium" @click="modeChange('answer_model')">预览</el-button>-->
    <!--            </el-button-group>-->
    <!--          </template>-->
    <!--          <template #right>-->
    <!--            <div v-if="activeMode === 'answer_model'" style="display: inline-block;">-->
    <!--              <el-dropdown trigger="click" @command="switchPreviewMode">-->
    <!--                <el-link  href="javascript:void(0)" style="margin-right: 8px;">辅助<i class="el-icon-arrow-down el-icon&#45;&#45;right" style="margin-left: 0;"></i></el-link>-->
    <!--                <template #dropdown>-->
    <!--                  <el-dropdown-menu>-->
    <!--                    <el-dropdown-item :class="{ 'modeChecked': variableConfig.showVariable === true}"  :command="1">显示辅助信息</el-dropdown-item>-->
    <!--                    <el-dropdown-item :class="{ 'modeChecked': variableConfig.showVariable === false}"  :command="0">隐藏辅助信息</el-dropdown-item>-->
    <!--                  </el-dropdown-menu>-->
    <!--                </template>-->
    <!--              </el-dropdown>-->
    <!--            </div>-->
    <!--            <el-button v-show="showDownload === 0" @click="publish" type="text" size="mini"-->
    <!--                       style="vertical-align: middle;color:#606266;font-size: 14px;">-->
    <!--              发布-->
    <!--            </el-button>-->
    <!--            <el-button v-show="activeMode === 'answer_model' && showDownload === 1"  id="previewDownloadButton"  @click="downloadProFile" type="text" size="mini"-->
    <!--                       style="vertical-align: middle;color:#606266;font-size: 14px;">-->
    <!--              <span>下载</span>-->
    <!--            </el-button>-->
    <!--            <el-button v-show="activeMode === 'answer_model' && showDownload === 0" @click="saveDocx" type="text" size="mini"-->
    <!--                       style="vertical-align: middle;color:#606266;font-size: 14px;">-->
    <!--              <span>使用</span>-->
    <!--            </el-button>-->
    <!--            <el-button  @click="shareAnswerSheet" id="shareButton"  type="text" size="mini"-->
    <!--                        style="vertical-align: middle;color:#606266;font-size: 14px;">-->
    <!--              <span>分享</span>-->
    <!--            </el-button>-->
    <!--            <el-button @click="gotoAnswerSheetInfo" type="text" size="mini"-->
    <!--                       style="vertical-align: middle;color:#606266;font-size: 14px;">-->
    <!--              <span>管理</span>-->
    <!--            </el-button>-->
    <!--    </template>-->
    <!--    </Header>-->
    <!--    </el-header>-->

    <el-container style="padding:0px 0px;overflow-y: hidden; padding-bottom: 0;">
      <el-aside id="question-container" :style="{width: `${leftWidth}px`}">
        <doc-questions ref="question"
                       :file-id="fileId" :draft-contract-mode="draftContractMode"
                       :leftWidth="leftWidth"
                       :getAssociationQuestionFunc="getAssociateQuestion"
                       :getAssociationVisibleControlQuestion="getAssociationVisibleControlQuestion"
                       :defaultMode="activeMode" :deleteQuestionRelatedArea="deleteQuestionRelatedArea"
                       @answer-changed="answerChanged"
                       @answer-all-question="answerAllQuestion" @refresh-editor-questions="refreshEditorQuestions"
                       @question-answer-info-changed="questionAnswerInfoChanged"
                       @mode-changed="modeChange"
                       :highlightEditorQuestionRelatedRange="highlightEditorQuestionRelatedRange"
                       :showVariable="variableConfig.showVariable"
                       @question-option-changed="questionOptionChanged"/>
      </el-aside>
      <el-divider direction="vertical" class="docTemplateDivider"></el-divider>
      <!--            <el-main id="editor-content-container" style="overflow: visible; padding-left: 5px; padding-right: 5px; padding-top: 10px;" :style="{width: rightWidth + 'px'}" :class="{'previewModeBackground': activeMode === 'answer_model'}">-->
      <el-main id="editor-content-container"
               style="overflow: visible; padding-left: 5px; padding-right: 5px; padding-top: 10px;"
               :style="{width: rightWidth + 'px'}" class="previewModeBackground">
        <EmptyEditorPlaceholder
            :info="{title:oldTitle}"
            v-if="currentMode == 'edit_model' && editorInitContentEmpty"
        ></EmptyEditorPlaceholder>
        <doc-content ref="content"
                     @editor-messages="handelEditorMessages"
                     @editor-content-changed="editorContentChanged"
                     @editor-ready="editorInitReady"
                     @editor-get-pro-data="handleEditorGetProData"
                     @editor-focus="handleEditorFocus"
                     :getSetting="getSetting"
                     :fileId="fileId"
                     :content="content"
                     :variableConfig="variableConfig"
                     :defaultMode="activeMode"/>
      </el-main>
    </el-container>
    <el-dialog
            v-model="shareDialogVisible"
            width="600px"
            :show-close="false"
            :close-on-click-modal="false"
            destroy-on-close
            custom-class="shareDialog"
            center>
      <div style="position: absolute;top: 16px;right: 20px;z-index: 2;cursor: pointer">
        <i class="el-icon el-icon-close" @click="shareDialogVisible=false"></i>
      </div>
      <share-answer-sheet :file-id="fileId" @close="shareDialogVisible=false" style="margin-top: -30px;"/>
    </el-dialog>

    <el-dialog
            v-model="saleDialogVisible"
            width="600px"
            :show-close="true"
            :close-on-click-modal="true"
            destroy-on-close
            center>
      <div class="sale-container">
        <div class="sale-info">
          <div style="font-weight: bold; color: black; font-size: 16px;">升级正式版</div>
          <img style="width: 215px; height: 215px" src="../assets/images/contact-qrcode.jpg">
          <div style="margin-top: 5px; font-weight: bold; color: black;">电话：17600619938</div>
        </div>
        <el-divider style="height: 347px" direction="vertical"></el-divider>
        <div class="sale-description">
          <img style="width: 215px; height: 215px" src="../assets/images/support-team.png">
          <div style="font-weight: bold; color: gray;">感谢您的试用，目前试用版已结束。</div>
          <div style="font-weight: bold; color: gray;">通过左侧联系方式，享受升级优惠：</div>
          <div class="sale-description-content">
            <div><img src="../assets/images/correct.png">更多的导出次数</div>
            <div><img src="../assets/images/correct.png">团队内模板分享</div>
            <div><img src="../assets/images/correct.png">团队成员管理</div>
            <div><img src="../assets/images/correct.png">专属客服</div>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
            v-model="showDeleteDocDialog"
            width="400px"
    >
      <span style="font-size:16px">{{ '确定删除吗 ?' }}</span>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="showDeleteDocDialog = false">取消</el-button>
        <el-button type="primary" @click="showDeleteDocDialog = false;deleteDoc()"
        >确定</el-button
        >
      </span>
      </template>
    </el-dialog>
    <TemplateAnswerSheetBasicSetting ref="basicSetting" :file-id="fileId" @setting-changed="settingChanged" style="margin-top: 5px;"></TemplateAnswerSheetBasicSetting>
    </el-container>
</template>

<script>
  import DocQuestions from "../components/DocQuestions";
  import DocContent from "../components/DocContent";
  import {ACTIVE_MODEL, convertContentToStringIFNotNull, EditorMsgType} from "../constant/question";
  import {canTakeTextAsDefaultAnswer, isTextType} from "../components/common/question";
  import {
    copyTemplateFileToDraftBox,
    deleteResources,
    draftContractByAnswers,
    getTemplateFileMeta, isSmarter, logToUranusEsRequest,
    updatePublishedTemplate
  } from "../api/api";
  import {getDownloadUrlByAnswerSheetId} from "../components/common/commonUtil";
  import Header from "../components/Header";
  import TemplateAnswerSheetInfo from "./TemplateAnswerSheetInfo";
  import ShareAnswerSheet from "../components/ShareAnswerSheet";
  import {ElLoading, ElMessage} from 'element-plus';
  import {checkUserBehaviorPermission,BehaviorPermission} from "../components/common/permissionUtil";
  import {
    activeHiddenTemplateFile,
    downloadByAnswerSheetId,
    saveDocxByAnswerSheetId,
    getFileOwnerDownloadQuota,
    saveMetaProFileTitle
  } from "../api/api";
  import config from "../config";
  // import contractCSS from "../assets/css/contract.css"
  import contractHeaderCSS from "../assets/css/contractHeader.css";

  import EditTitle from "../components/EditTitle";
  import {ContractApprovalStatus} from "../constant/contract";
  import {globalTagClick} from "../assets/js/tag/tag";
  import {colorLog} from "../util";
  import {CUSTOM_EVENT, logToES} from "../assets/js/tag";
  import Mask from "../components/universalUI/Mask";
  import EmptyEditorPlaceholder from "../components/EmptyEditorPlaceholder";
  import HelpListItem from "../components/header/HelpListItem";
  import ExitBtn from "../components/universalUI/ExitBtn";
  import TemplateAnswerSheetBasicSetting from "../components/TemplateAnswerSheetBasicSetting";


  export default {
    name: "DocTemplateDev",
    props: ['fileId', 'content', 'mode','templateId'],
    inject:['reload'],
    data: function () {
      return {
        saleDialogVisible: false,
        shareDialogVisible: false,
        loading: true,
        loadingInstance: null,
        checkContentSaverInterval: null,
        activeMode: this.mode || ACTIVE_MODEL.EDIT_MODEL,
        oldActiveMode: this.mode || ACTIVE_MODEL.EDIT_MODEL,
        leftWidth: window.innerWidth * 0.33,
        divider: null,
        isDraging: false,
        mouseCurrentX: -1,
        showVariable: false,
        windowWidth: window.innerWidth,
        rightWidth: window.innerWidth * 0.37 - 59,
        variableConfig: {
          showVariable: true
        },
        titleEditMode: false,
        rightLeftMaxRatio: 2.5,
        rightLeftMinRatio: 1,
        FileMenuVisible:false,
        settingMenuVisible:false,
        helpMenuVisible:false,
        currentMode:'edit_model',
        editorInitContentEmpty:this.content && this.content.blocks.length == 0,
        modes:[
          {
            value: 'edit_model',
            label: '编辑',
          },
          {
            value: 'answer_model',
            label: '预览',
          }
        ],
        oldTitle:this.$route.query.fileName?this.$route.query.fileName.replace(/.pro$/,''):'',
        showDeleteDocDialog:false,
        draftContractMode:false,
        checkUserBehaviorPermission,
        BehaviorPermission,
        userInfo:JSON.parse(sessionStorage.getItem("userInfo")),
        publishedTemplate: null,
        publishedTemplateId: null,
        isSmarter:false,
      };

    },
    mounted: function () {
      if(this.mode){
        this.currentMode=this.mode;
      }
      if(this.templateId){
        this.draftContractMode=true;
      }
      this.init(this.fileId);
      this.bindDividerEvent();
      getTemplateFileMeta(this.fileId).then(res => {
        if (res.data.code === 0) {
          const data = res.data.data
          if (data.published) {
            this.publishedTemplate = data.publishedTemplate;
            this.publishedTemplateId = this.publishedTemplate.id;
          }
          data.title = data.title.replace(/.(pro|docx|doc)$/,'')
          this.oldTitle = data.title;
          // this.$refs.editTitle.title = this.oldTitle;
        } else {
          console.error('getFileTemplatePublish  err:'+res.data.code);
        }
      }).catch(err => {
        console.error('getFileTemplatePublish  err:', err);
      })
      this.judgeSmarter();
    },

    methods: {
      settingChanged: function (_setting) {
        this.$refs.question.setting = _setting;
      },
      onSettingMenuClicked(){
        this.settingMenuVisible = true;
      },
      onTemplateTipClicked(){
        this.settingMenuVisible = false;
        this.$nextTick(()=>{
          this.$refs.basicSetting.openTemplateTip();
        })
      },
      judgeSmarter:function(){
        isSmarter().then(res => {
          if (res.data.code === 0) {
            this.isSmarter = res.data.data;
          }
        }).catch(error => {
          console.error(error);
        });
      },
      getTemplateStatusText: function () {
        if (this.publishedTemplate) {
          return '模板';
        }

        return '模板草稿';
      },
      getTemplateStatusClass: function () {
        if (this.publishedTemplate) {
          if (this.publishedTemplate.approvalStatus === 0) {
            return 'bg-dandelion';
          } else if (this.publishedTemplate.approvalStatus === 1) {
            return 'bg-mint';
          } else if (this.publishedTemplate.approvalStatus === 2) {
            return 'bg-mona-lisa';
          }
        }

        return 'stage-white';
      },
      FileBtnHover(){
        if(this.menuActive){
          this.FileMenuVisible=true
        }
      },
      helpBtnHover(){
        if (this.menuActive) {
          this.$refs.supplementPopover.open=false;
          this.helpMenuVisible = true;
        }
      },
      deleteDoc(){

        let resources={templateIds:[],proIds:[]}
        resources.proIds.push(this.fileId)
        if(this.templateId){
          resources.templateIds.push(this.templateId);
        }
        deleteResources(resources,false).then(res=>{
          if(res.status==200){
            this.backToIndex();
          }
        })
      },
      changeTitle(newTitle){
        if (this.publishedTemplateId) {
          this.publishedTemplate.title = newTitle;
          updatePublishedTemplate(this.publishedTemplateId, this.publishedTemplate).then(res => {
            console.log('updatePublishedTemplate  res === ', res);
            if (res.data.code === 0) {
              this.oldTitle = newTitle;
              ElMessage.success('修改成功');
              return true;
            } else {
              this.$refs.editTitle.newTitle = this.oldTitle;
              ElMessage.error("修改失败:" + res.data.msg);
              return false;
            }
          }).catch(err => {
            this.$refs.editTitle.newTitle = this.oldTitle;
            ElMessage.error("修改失败");
            return false;
          })
        } else {
          return saveMetaProFileTitle(this.fileId,newTitle + '.pro').then(res=>{
            if(res.data.code === 0){
              this.oldTitle = newTitle;
              ElMessage.success('修改成功');
              return true;
            }else{
              this.$refs.editTitle.newTitle = this.oldTitle;
              ElMessage.error("修改失败");
              return false;
            }
          });
        }


      },
      backToIndex(){
        this.$router.back();
      },
      showVariableChanged: function (newValue) {
        this.$refs['content'].variableConfigChange({"showKH": newValue, "showVariable": newValue});
      },
      bindDividerEvent: function () {
        this.divider = document.querySelector('.docTemplateDivider');
        if (this.divider) {
          this.divider.onmousedown = (e) => {
            this.isDraging = true;
            this.mouseCurrentX = e.pageX;
            this.divider.classList.add('docTemplateDividerHovered');
            e.stopPropagation();
          }
        }
        document.addEventListener('mousemove', (event) => {
          if (this.isDraging) {
            this.dividerDragging(event);
            event.stopPropagation();
            event.preventDefault();
          }
        });
        document.addEventListener('mouseup', (event) => {
          if (this.isDraging) {
            this.isDraging = false;
            this.mouseCurrentX = -1;
            this.divider.classList.remove('docTemplateDividerHovered');
          }
        });
        window.addEventListener('resize', () => {
          this.windowWidth = window.innerWidth;
          this.rightWidth = this.windowWidth - this.leftWidth - 59;
        });
      },
      dividerDragging: function (event) {
        const questionContainer = document.querySelector("#question-container");
        const baseWidth = parseInt(questionContainer.style.width);
        const offset = event.pageX - this.mouseCurrentX;
        const windowWidth = window.innerWidth;
        const newLeftWidth = baseWidth + offset;
        const newRightWidth = windowWidth - newLeftWidth - 59;
        const rightLeftRatio = newRightWidth / newLeftWidth;
        if (rightLeftRatio < this.rightLeftMinRatio || rightLeftRatio > this.rightLeftMaxRatio) {
          return;
        }
        this.leftWidth = newLeftWidth;
        this.rightWidth = newRightWidth;
        this.mouseCurrentX = event.pageX;
        this.$refs['content'].editorResize();
      },
      gotoAnswerSheetInfo: function () {
        this.$router.push({
          name: 'answerSheetInfo',
          params: {
            fileId: this.fileId
          }
        })
      },
      /**
       * 将回答问题的界面分享给用户
       */
      shareAnswerSheet: function () {
        this.shareDialogVisible = true;
      },
      init: function (fileId) {
        this.$refs.question.init(fileId);
      },
      handelEditorMessages: function (msg) {
        // console.log('received editor msg**********');
        // console.log(msg)
        for (let i = 0; i < msg.length; i++) {
          this.handelEditorMessage(msg[i]);
        }
      },
      /**
       * 切换editorJs的模式。
       */
      switchPreviewMode: function (show) {
        this.variableConfig.showVariable = show === 1;
      },
      handelEditorMessage: function (msg) {
        if (msg.action === EditorMsgType.ADD) {
          this.newQuestionAndLink(msg);
        } else if (msg.action === EditorMsgType.LINK) {
          this.linkQuestion(msg);
        } else if (msg.action === EditorMsgType.QUESTION_LIST_ACCESS) {
          this.questionListAccess(msg);
        } else if (msg.action === EditorMsgType.QUESTION_ACCESS) {
          this.questionAccess(msg)
        } else if (msg.action === EditorMsgType.FOCUS_QUESTION) {
          this.$refs.question.focusQuestionByUUID(msg.params.questionUUID);
        } else if (msg.action === EditorMsgType.ADD_OR_LINK) {
          if (!this.checkBeforeLink(msg)) {
            return
          }
          this.$refs.question.openAddOrLinkQuestionDialog(msg.params, msg.callback);
        } else if (msg.action === EditorMsgType.SHOW_MESSAGE) {
          ElMessage({
            showClose: true,
            message: msg.params.message,
            type: msg.params.type
          });
        } else if (msg.action === EditorMsgType.DELETE_VARIABLE) {
          console.log('event_port params:', msg.params);
          logToES({event_name:CUSTOM_EVENT.DELETE_VARIABLE.name, value:1, text: JSON.stringify(msg.params)})
        }
      },
      //新建问题，并将它们关联起来
      /**
       * @deprecated
       * @param msg
       */
      newQuestionAndLink: function (msg) {
        if (!this.checkBeforeLink(msg)) {
          return
        }
        // let defaultVal = {answerType: msg.params.type, title: msg.params.text};
        //todo msg.params.text需要作为预览模式该问题的默认答案。
        let defaultVal = {answerType: msg.params.type};
        //如果是大区域里面画小区域，则将该小区域的依赖问题的id
        if (msg.params.parentQuestionUUID) {
          defaultVal.showControlAnsId = this.$refs.question.getIdByUUID(msg.params.parentQuestionUUID)
        }
        this.$refs.question.newAndSaveQuestion(defaultVal).then((newQuestion) => {
          // console.log('newAndSaveQuestion  newQuestion:', newQuestion);
          if (canTakeTextAsDefaultAnswer(newQuestion)) {
            //如果是文本类型的话，就把关联的文本作为默认的答案
            // this.$refs.question.insertAnswerCache(newQuestion, msg.params.text);
            this.emptyQuestion.defaultAnswer = this.addOrLinkParam.text
          }
          //如果是小区域外面画大区域
          this.updateChildrenRelatedQuestion(msg, newQuestion.uuid);
          if (msg.callback) {
            msg.callback({code: 0, msg: "success", data: newQuestion})
          }
        }).catch(err => {
          if (msg.callback) {
            msg.callback({code: -1, msg: "新建问题失败"})
          }
        });
      },
      linkQuestion: function (msg) {
        if (!this.checkBeforeLink(msg)) {
          return
        }
        //如果是大区域里面画小区域，则将该小区域的依赖问题的id
        if (msg.params.parentQuestionUUID) {
          this.$refs.question.updateDefaultRelatedQuestion(msg.params.questionUUID, msg.params.parentQuestionUUID);
        }

        setTimeout(() => {
          let question = this.$refs.question.findItemByUUID(msg.params.questionUUID)
          this.$refs.question.focusQuestionByUUID(msg.params.questionUUID);
          //如果是小区域外面画大区域
          this.updateChildrenRelatedQuestion(msg, msg.params.questionUUID)
          if (msg.callback) {
            console.log('linkQuestion***8')
            msg.callback({code: 0, msg: "success", data: question})
          }
        }, 100)
      },
      checkBeforeLink: function (msg) {
        //如果是小区域外面画大区域，则将小区域的问题的依赖问题设置为大区域的问题
        if (msg.params.childQuetionsUUID) {
          for (let i = 0; i < msg.params.childQuetionsUUID.length; i++) {
            //判断该小区域说关联的问题是否已经有依赖问题了。
            let hasRelatedQuestion = this.$refs.question.checkQuestionHasRelatedQuestion(msg.params.childQuetionsUUID[i]);
            if (hasRelatedQuestion) {
              //如果有依赖问题，则不允许它新建问题
              if (msg.callback) {
                msg.callback({code: -1, msg: "更新依赖问题失败，该问题已有依赖"})
              }
              return false
            }
          }
        }
        return true
      },
      updateChildrenRelatedQuestion(msg, relatedUUID) {
        if (msg.params.childQuetionsUUID) {
          for (let i = 0; i < msg.params.childQuetionsUUID.length; i++) {
            this.$refs.question.updateDefaultRelatedQuestion(msg.params.childQuetionsUUID[i], relatedUUID);
          }
        }
      },
      //将目前所有的问题返回给editorJs
      questionListAccess: function (msg) {
        if (msg.callback) {
          let items = this.$refs.question.getItems().map(item => {
            // return {answerName: item.answerName, uuid: item.uuid, answerType: item.answerType}
            return item;
          })
          // console.log(items)
          msg.callback(items);
        }
      },
      questionAccess: function (msg) {
        if (msg.callback) {
          const question = this.$refs.question.findItemByUUID(msg.params.uuid);
          msg.callback(question);
        }
      },
      getAssociateQuestion: function () {
        return this.$refs['content'].getAssociateQuestion().map(item => item.uuid)
        // if (param && param.callback) {
        //     this.$refs['content'].getAssociateQuestion().then((data) => {
        //         console.log('getAssociateQuestion  data === ', data);
        //         param.callback(data.map(item => item.uuid));
        //     })
        // }
      },
      /**
       * 获取关联了显隐设置区域的问题的uuid列表
       */
      getAssociationVisibleControlQuestion: function () {
        return this.$refs['content'].getAssociationVisibleControlQuestion()
      },
      deleteQuestionRelatedArea: function (params) {
        this.$refs['content'].deleteQuestionRelatedArea(params)
      },
      questionAnswerInfoChanged: function (question) {
        // console.log('notify editorJS variable changed')
        this.$refs['content'].questionVariableChanged(question)
      },
      highlightEditorQuestionRelatedRange: function (questionUUID, twinkle, directory, index) {
        return this.$refs['content'].highlightQuestion(questionUUID, twinkle, directory, index);
      },
      answerChanged: function (params) {
        this.answerSheet = params
        this.$refs['content'].answerChanged(params.answer.questionUUID, params.answer.answer.content, params.involvedQuestionVisible, params.scrollToRect);
      },
      refreshEditorQuestions: function (questions) {
        this.$refs['content'].refreshQuestions(questions);
      },
      answerAllQuestion: function (answers) {
        this.$refs['content'].answerAllQuestion(answers);
      },
      editorInitReady: function () {
        // if (this.activeMode === ACTIVE_MODEL.ANSWER_MODEL) {
        //     this.$refs['question'].createAnswerSheet();
        // }
        if (this.$refs['question'] && this.$refs['question'].editorInitReady) {
          this.$refs['question'].editorInitReady();
        }
      },
      editorContentChanged: function () {
        if (this.$refs['question'] && this.$refs['question'].editorContentChanged) {
          this.$refs['question'].editorContentChanged();
        }
      },
      modeChange: function (mode) {
        if (mode === this.oldActiveModel || this.loadingInstance !== null) {
          return false;
        }

        // /**
        //  * 每次切换到编辑模式时，调用激活隐藏文件接口
        //  * 接口会自行判断是否需要激活
        //  */
        // if (mode === 'edit_model') {
        //   activeHiddenTemplateFile({fileId: this.fileId});
        // }
        if (mode === 'edit_model' && this.draftContractMode) {
          //如果是起草模式切换到编辑模式，则需要将模板拷贝到个人个人目录下面。
          this.switchEditModeOnDraftMode();
          return ;
        }

        this.activeMode = mode;
        this.oldActiveModel = mode;
        if (this.$refs['content'].saver) {
          this.loadingInstance = ElLoading.service({
            fullscreen: true,
            body: true,
            lock: true,
            spinner: 'el-icon-loading'
          });
          console.log('等待保存中！！！！！！');
          this.checkContentSaverInterval = setInterval(() => {
            if (!this.$refs['content'].saver) {
              console.log('！！！保存结束，开始切换模式！！！！！');
              clearInterval(this.checkContentSaverInterval);
              this.doModeChange(mode);
            }
          }, 1000);
        } else {
          this.loadingInstance = ElLoading.service({
            fullscreen: true,
            body: true,
            lock: true,
            spinner: 'el-icon-loading'
          });
          this.doModeChange(mode);
        }
      },
      switchEditModeOnDraftMode:function(){
        this.$confirm('系统将中断起草行为，并复制当前模板为模板草稿用以编辑，是否继续？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          copyTemplateFileToDraftBox(this.templateId).then(res => {
            if (res.data.code === 0) {
              let newPage = this.$router.resolve({
                name: 'doc',
                query: {fileId: res.data.data, mode: ACTIVE_MODEL.EDIT_MODEL}
              })

              window.open(newPage.href)
              // 暂时先改成新开窗口，reload有问题
              // this.$router.push({
              //   name: 'doc',
              //   query: {fileId: res.data.data, mode: ACTIVE_MODEL.EDIT_MODEL}
              // }).then(()=>{
              //   this.reload();
              // })
            } else {
              this.$message.error('创建模板草稿失败');
              this.currentMode = ACTIVE_MODEL.ANSWER_MODEL;
            }
          }).catch(error => {
            this.$message.error('创建模板草稿失败');
            this.currentMode = ACTIVE_MODEL.ANSWER_MODEL;
          })
        }).catch(error => {
          // 用户取消了，切回预览模式。
          this.currentMode = ACTIVE_MODEL.ANSWER_MODEL;
        });
      },
      doModeChange: function (mode) {
        this.$refs['question'].activeModelSwitched(mode);
        setTimeout(() => {
          this.$refs['content'].modeChange(mode, () => {
            if (mode === ACTIVE_MODEL.ANSWER_MODEL) {
              this.$refs['question'].answerAllQuestion(() => {
                this.loadingInstance.close();
                this.loadingInstance = null;
              });
            } else {
              this.loadingInstance.close();
              this.loadingInstance = null;
            }
            // setTimeout(() => { this.loadingInstance.close(); }, 500);
          });
        }, 200);
      },
      getSetting: function () {
        return this.$refs['question']['setting'];
      },
      downloadProFile() {
        this.$refs["question"].getAnswerSheetId().then((item) => {
                  this.loadingInstance = ElLoading.service({
                    fullscreen: true,
                    body: true,
                    lock: true,
                    spinner: 'el-icon-loading'
                  });
                  downloadByAnswerSheetId(this.fileId, item).then(res => {
                    this.loadingInstance.close();
                    this.loadingInstance = null;
                    let url = window.URL.createObjectURL(res.data);
                    let a = document.createElement('a');
                    let filename = decodeURIComponent(res.headers['filename']);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                  }).catch(err => {
                    this.loadingInstance.close();
                    this.loadingInstance = null;
                    err.response.data.text().then(message => {
                      const errorMessage = JSON.parse(message).message;
                      console.log('errorMessage === ' + errorMessage);
                      if (errorMessage === '下载额度不足') {
                        getFileOwnerDownloadQuota(this.fileId).then(quotaRes => {
                          console.log("quotaRes === ", quotaRes)
                          if (quotaRes.data.data === 10) {
                            this.saleDialogVisible = true;
                          } else {
                            ElMessage({
                              showClose: true,
                              message: JSON.parse(message).message,
                              type: 'error'
                            });
                          }
                        }).catch(quotaError => {
                          ElMessage({
                            showClose: true,
                            message: JSON.parse(message).message,
                            type: 'error'
                          });
                        })
                      } else {
                        ElMessage({
                          showClose: true,
                          message: JSON.parse(message).message,
                          type: 'error'
                        });
                      }
                    })
                  })
                }
        )
      },
      publish: function () {
        console.log('publish   this.fileId ==== ', this.fileId);
        // window.open('/#/templatePublish?fileId=' + this.fileId);
        this.$router.push({
          name:'templatePublish',
          query:{
            fileId: this.fileId,
            title: this.oldTitle
          }
        })
      },
      questionOptionChanged: function (params) {
        this.$refs['content'].questionOptionChanged(params['questionId'], params['oldName'], params['newName']);
      },
      getAnswersParam: function () {
        const answers = [], oldAnswerMap = new Map();

        this.$refs.question.answerSheet.forEach(as => {
          const copyAnswer = Object.assign({}, as.answer);
          copyAnswer.content = convertContentToStringIFNotNull(copyAnswer.content);
          answers.push(copyAnswer);
          oldAnswerMap.set(as.question.id, as.answer);
        });
        return {answers:answers, oldAnswerMap: oldAnswerMap}
      },
      createContract:function(){

        let {answers} = this.getAnswersParam();
        this.loadingInstance = ElLoading.service({ fullscreen: true, body: true, lock: true, spinner: 'el-icon-loading' });
        this.loadingInstance.setText('创建中')
        draftContractByAnswers(answers, this.templateId).then(res => {
          if (res.data.code === 0) {
            globalTagClick('template-create-contract-draft');
            this.loadingInstance.setText('创建成功')
            this.$message.success('创建合同成功');
            let contract = res.data.data;
            this.$router.push({name: 'contractView', query: {id: contract.id, name: contract.title}})
          } else if (res.data.code === 401) {
            this.$message.error('暂无权限，请在当前浏览器登录后操作');
          } else {
            this.$message.error('创建合同失败，请稍后再试');
          }
        }).catch(error => {
          console.error(error);
          this.$message.error('创建合同失败，请稍后再试');
        }).finally(() => {
          this.loadingInstance.close()
        });
      },
      askForDelete(){
        if(this.templateId && this.userInfo && checkUserBehaviorPermission(this.userInfo.mainSiteTeamId,BehaviorPermission.TEMPLATE_APPROVAL)){
          this.showDeleteDocDialog=true;
        }

      },
      handleEditorGetProData(data){
        this.editorInitContentEmpty = data.blocks.length == 0;
        // this.editorInitContentEmpty = true;
      },
      handleEditorFocus(){
        this.editorInitContentEmpty = false;
      }

    },
    components: {
      TemplateAnswerSheetBasicSetting,
      ExitBtn,
      HelpListItem,
      EmptyEditorPlaceholder,
      Mask,
      ShareAnswerSheet, TemplateAnswerSheetInfo, DocContent, DocQuestions, Header, EditTitle}
  }
</script>

<style>
  .shareDialog .el-dialog__body {
    padding: 0;
    position: relative;
  }
</style>
<style scoped lang="scss">
  .el-header {
    padding: 0;

    i {
      margin-left: 8px;
    }
  }

  .pointer {
    cursor: pointer;
  }

  // #editor-content-container .el-loading-spinner {
  //     top: 200px;
  // }
  .previewModeBackground {
    background-color: #F2F3F5;
  }

  .docTemplateDivider {
    height: 100%;
    cursor: ew-resize;
    width: 3px;
    margin-left: 0;
  }

  .docTemplateDivider:hover {
    background-color: #3370ff;
  }

  .docTemplateDividerHovered {
    background-color: #3370ff;
  }

  .modeChecked {
    color: #FFF;
    background-color: #409EFF;
    border-color: #409EFF;
  }

  .sale-info {
    // background-color: #F2F2F2;
    text-align: center;
  }

  .sale-container {
    display: flex;
    justify-content: space-around;
  }

  .sale-description-content {
    font-weight: bold;
    color: black;
  }

  .sale-description-content img {
    display: inline-block;
    width: 12px;
    height: 12px;
  }

  .sale-description-content div {
    margin: 3px 0;
  }

  /*header style*/
  #contract-edit-header-right{
    display: flex;
    align-items: center;
  }

  #ceh-right-switchMode{
    width: 95px;
  }
  .darkCircleHover{
    border-radius: 5px;
  }
  .darkCircleHover:hover{
    background: rgba(0,0,0,.15);
  }

  #topBar-left-title{
    font-size: 22px;
    font-weight: bold;
    height: 30px;
  }

  #contract-edit-header-right .edit-header-right-btn{
    height: 40px;
    box-sizing:border-box;
    padding: 0 10px;
    border-radius: 0;
    font-size: 16px;
  }
  .publish-button{
    cursor:pointer;
    padding:9px 15px;
    font-size:12px;
    line-height: 12px;
    background: var(--lightBlue);
    color: white;
    margin-right: 15px;
  }

  .publish-button:hover{
    background:var(--darkBlue);
  }

  .color-blue {
    color:var(--lightBlue);
  }
  #question-container{
    z-index:2
  }
</style>
