<template>
    <div style="height:395px">
        <el-row style="height: 100%;">
            <el-col :span="12" style="background-color: #F2F2F2;padding: 12px 20px;height: 100%;">
                <div class="shareBoldTitle" >小程序分享</div>
                <div style="text-align: center;margin-top: 30px;">
                    <template v-if="validate">
                        <div style="height: 200px;">
                            <el-image  :src="miniProQRCodeSrc" :preview-src-list="[miniProQRCodeSrc]" style="width: 200px;height: 200px;line-height: 200px;">
                                <template #error>　</template>
                            </el-image>
                        </div>
                        <div>
                            <span>MetaGo</span>
                        </div>
                    </template>
                    <div v-else style="height: 216px;text-align: left;padding:0 20px;">
                        <div><i class="icon iconfont icon-wenhao" style="font-size: 36px;font-weight: bold;"></i></div>
                        <div style="margin-top: 10px;">{{illegalTip}}</div>
                    </div>
                </div>
                <div  class="shareBoldTitle" style="margin-top: 10px">链接分享</div>
                <div style="margin-top: 10px;">
                    <el-input placeholder="请输入内容" v-model="shareShotUrl" size="small" readonly  v-if="validate">
                        <template #append>
                            <span @click="copyUrl" class="copyUrl" ref="copyText" :data-clipboard-text="shareShotUrl" @mousedown="mouseDownCopyUrl" >复制</span>
                        </template>
                    </el-input>

                    <el-input  v-model="illegalTip" size="small" readonly disabled  v-else>
                        <template #append>
                            <span @click="copyUrl" class="copyUrl" ref="copyText" :data-clipboard-text="shareShotUrl" @mousedown="mouseDownCopyUrl">复制</span>
                        </template>
                    </el-input>
                </div>

            </el-col>
            <el-col :span="12">
                <div style="text-align: center;position: relative;margin-top: 10px;">
                    <img src="../assets/images/share_image.png" style="width: 120px;height: 110px;">
                </div>
                <div class="shareDefaultFont" style="color: gray;padding: 15px 30px 20px;line-height: 1.5;">您可以通过微信小程序或链接方式将文档分享给朋友或自己，方便手机使用</div>

                <div   class="shareBoldTitle" style="width: 128px;text-align: center;margin-left:30px;border-bottom: 5px solid black;border-radius: 2px;">高级选项</div>
                <div style="display: block;height: 1px;width: 100%;margin: -1px 0 0 0;background-color: #DCDFE6;"></div>
                <div name="advance" class="shareDefaultFont" style="color: #4B595F;padding:  15px 30px;line-height: 2;">

                        <div style="display: flex;justify-content: space-between;align-items: center;margin-top:-11px;" v-if="isSmarter">
                            system推广开启
                            <el-switch v-model="system"></el-switch>
                        </div>

                        <div style="display: flex;justify-content: space-between;align-items: center;" >
                            文档可下载
                            <el-switch
                                    v-model="param.downloadAfterAnswerSheetSubmit"
                                    @change="paramChanged"
                                    :disabled="!param.showAllGroup"
                            >
                            </el-switch>
                        </div>

                        <div style="display: flex;justify-content: space-between;align-items: center;"  >
                            文档可预览
                            <el-switch
                                    v-model="param.canPreview"
                                    @change="paramChanged"
                                    :disabled="!param.showAllGroup"
                            >
                            </el-switch>
                        </div>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            分享所有组问题
                            <el-switch
                                    v-model="param.showAllGroup"
                                    @change="showAllGroupChanged"
                            >
                            </el-switch>
                        </div>
                        <div style="margin-top: 6px;">
                            <el-select
                                    v-if="!param.showAllGroup"
                                    v-model="param.showGroupIds"
                                    multiple
                                    filterable
                                    size="small"
                                    collapse-tags
                                    default-first-option="true"
                                    popper-class="test"
                                    clearable="true"
                                    :popper-append-to-body="true"
                                    placeholder="问题组">
                                <el-option
                                        v-for="item in groups"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import config from "../config";
    import {
        generateShortUrl,
        getAnswerSheetShareMiniProQrCode,
        getTemplateQuestionGroups,
        getTemplateSetting, isSmarter, saveShareInfo,
        saveTemplateSetting
    } from "../api/api";
    export default {
        name: "shareAnswerSheet",
        props:['fileId'],
        data:function () {
            return {
                webShareUrl: null,
                shareShotUrl: null,
                firstCopy: true,
                miniProQRCodeSrc: null,
                shareKeyId: null,
                groups: [],
                validate: false,
                illegalTip: '',
                param: {
                    fileId: this.fileId,
                    showGroupIds: [],
                    showAllGroup: true,
                    downloadAfterAnswerSheetSubmit: true,
                    canPreview: true,
                    authInfo: null
                },
                isSmarter:false,
                system: false,
                preGenerateSystem: false,
                preParam: '',
                saveParamTimer: null
            };
        },
        watch:{
            param:{
                handler: function (newVal, oldVal) {
                    console.log('param changed*********88')
                    if (!this.param.showAllGroup && this.param.showGroupIds.length === 0) {
                        this.setValidate(false, '请在右侧下拉框中至少选择一个需要分享的组')
                    } else {
                        this.setValidate(true)
                        if (this.saveParamTimer) {
                            clearTimeout(this.saveParamTimer);
                        }
                        this.saveParamTimer = setTimeout(() => this.saveParam(), 500)
                    }
                },
                deep:true,
            },
            system:function(){
                if (this.system !== this.preGenerateSystem) {
                    this.webShareUrl = this.generateShareUrl();
                    this.generateShortUrlFunc(this.webShareUrl);
                }
            },
            shareKeyId:function () {
                this.getMiniProCode()
                this.webShareUrl = this.generateShareUrl();
                this.generateShortUrlFunc(this.webShareUrl);
            }
        },
        mounted() {
            // this.getMiniProCode();
            this.judgeSmarter();
            this.loadParam();
            this.loadSetting();
            this.getTemplateQuestionGroups();
            // this.webShareUrl = this.generateShareUrl();
            // console.log('********' + this.webShareUrl);
        },
        methods:{
            judgeSmarter:function(){
                isSmarter().then(res => {
                    if (res.data.code === 0) {
                        this.isSmarter = res.data.data;
                    }
                }).catch(error => {
                    console.error(error);
                });
            },
            setValidate: function (validate, illegalTip) {
                this.validate = validate;
                if (!validate) {
                    this.illegalTip = illegalTip;
                }
            },
            showAllGroupChanged:function(showAllGroup){
                if (!showAllGroup) {
                    this.param.canPreview = false;
                    this.param.downloadAfterAnswerSheetSubmit = false;
                }
            },
            getTemplateQuestionGroups:function(){
                getTemplateQuestionGroups(this.fileId).then(res=>{
                    this.groups = res.data.data;
                    if (this.groups.length > 0) {
                        this.$nextTick(() => this.showGroupIds = [this.groups[0].id]);
                    }
                });
            },
            generateShareUrl:function () {
                // const param = this.param;
                // const data = window.btoa(JSON.stringify(param));
                // return location.origin + location.pathname + this.$router.resolve({
                let resolveObj = {
                    name: 'answerQuestion',
                    query: {
                        data: this.shareKeyId
                    }
                };
                if (this.system) {
                    resolveObj.query.system = 1;
                }
                this.preGenerateSystem = this.system;
                return location.pathname + this.$router.resolve(resolveObj).href;
            },
            loadParam:function(){
                const param = localStorage.getItem(this.getSaveParamKey())
                if (param) {
                    try {
                        this.param = Object.assign(this.param, JSON.parse(param));
                    }catch (e) {
                        localStorage.removeItem(this.getSaveParamKey());
                    }
                }
            },
            loadSetting:function(){
                getTemplateSetting(this.fileId).then(res=>{
                    console.log(res)
                    if (res.data.code === 0) {
                        const setting = res.data.data
                        this.param.authInfo = setting.authInfo;
                    }
                });
            },
            getSaveParamKey:function(){
                return 'templateShareSetting' + this.fileId;
            },
            paramChanged: function () {
                let cacheParam = {canPreview:this.param.canPreview,downloadAfterAnswerSheetSubmit:this.param.downloadAfterAnswerSheetSubmit}
                localStorage.setItem(this.getSaveParamKey(), JSON.stringify(cacheParam));
            },
            // downloadAfterAnswerSheetSubmitChanged:function(val){
            //     this.setting.downloadAfterAnswerSheetSubmit = val ? 1 : 0;
            //     saveTemplateSetting(this.setting).then(res => {
            //         console.log(res)
            //         console.log('保存成功')
            //     });
            // },
            saveParam:function(){
                if (this.preParam === JSON.stringify(this.param)) {
                    return
                }
                this.preParam = JSON.stringify(this.param);
                saveShareInfo(this.param).then(res=>{
                    if (res.data.code === 0) {
                        this.shareKeyId = res.data.data;
                    }
                })
            },
            generateShortUrlFunc:function(webShareUrl){
                generateShortUrl(webShareUrl).then(res=>{
                    if (res.data.code === 0) {
                        let baseUrl = config.baseUrl
                        if (baseUrl.endsWith("/")) {
                            baseUrl = baseUrl.substring(0, baseUrl.length - 1);
                        }
                        this.shareShotUrl = baseUrl+ res.data.data;
                    }
                })
            },
            getMiniProCode:function(){
                console.log('getMinioProCode')
                getAnswerSheetShareMiniProQrCode(this.shareKeyId,100,true).then(res=>{
                    if (res.data.code === 0) {
                        this.miniProQRCodeSrc = 'data:image/png;base64,' + res.data.data;
                    }
                })
            },
            copyUrl:function () {
                console.log('copy*******')
                let clipboard = new this.$clipboard(this.$refs.copyText,{
                    text: (trigger)=>{
                        return this.shareShotUrl;
                    }
                });
                clipboard.on('success', ()=> {
                    this.firstCopy = false;
                    this.$message.success("复制成功")
                    clipboard.destroy()
                });
                clipboard.on('error', () => {
                    this.firstCopy = false;
                    this.$message.error("复制失败")
                    clipboard.destroy()
                });
            },
            mouseDownCopyUrl:function () {
                console.log(`validate:${this.validate}` );
                if (!this.validate) {
                    this.$message.error(this.illegalTip);
                    return;
                }
                if (this.firstCopy) {
                    this.copyUrl();
                }
            }
        },
        components: {}
    }
</script>

<style scoped>
    .shareBoldTitle{
        font-size: 16px;
        color:black;
        font-weight: bold;
        line-height: 2;
    }
    .shareDefaultFont{
        font-size: 14px;
        font-weight: bold;
        color: black;
    }
    .el-header{
        padding:0
    }
    .copyUrl{
        cursor: pointer;
    }
</style>
