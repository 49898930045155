<template>
  <Mask
      :display="templateTipVisible ? 'block':'none'"
      :z-index="999"
      @showCloseBtnClicked="handleCancel"
  >
    <div class="template-tip-wrap insetCenter">
      <dialog-close-btn @click="handleCancel"></dialog-close-btn>
      <el-card class="template-answer-sheet-basic-setting">
        <h3 class="dq-tt-title">模板提示</h3>
        <el-form>
          <el-form-item label="提示标题" :label-width="labelWidth">
            <el-input placeholder="请输入提示标题" v-model="setting.questionnaireTitle"></el-input>
          </el-form-item>
          <el-form-item label="提示内容" :label-width="labelWidth">
            <el-input placeholder="请输入提示内容" v-model="setting.questionnaireIntro" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <p class="tasbs-content-tip">
          用户从<b>模板起草</b>合同时，此提示显示在正文开头，用户可据此判断模板是否适用于当前交易场景
        </p>
        <div class="tasbs-prompt">
          <div class="tasbs-cancel prompt-item" @click="handleCancel">取消</div>
          <div class="tasbs-confirm prompt-item" @click="handleConfirmed">确认</div>
        </div>
      </el-card>
    </div>
  </Mask>


</template>

<script>
    import {
        getTemplateSetting, saveTemplateSetting,
    } from "../api/api";
    import Mask from "./universalUI/Mask";
    import {deepClone} from "../util";
    import DialogCloseBtn from "./universalUI/DialogCloseBtn";
    import {ElMessage} from "element-plus";
    export default {
        name: "templateAnswerSheetBasicSetting",
        props:['fileId'],
        emits: ["setting-changed"],
        data:function () {
            return {
                setting: {
                    questionnaireTitle: '',
                    questionnaireIntro: ''
                },
              settingCopy:null,
                saver: null,
              templateTipVisible:false,
              labelWidth:'70px'
            }
        },
        watch:{
            setting:{
                handler:function(){

                },
                deep:true
            }
        },
        mounted() {
            if (this._setting) {
                this.setting = this._setting;
            } else {
                this.getTemplateSetting();
            }
        },
        methods:{
          openTemplateTip(){
            this.settingCopy = deepClone(this.setting);
            this.templateTipVisible = true;
          },
          closeTemplateTip(){
            this.templateTipVisible = false;
          },
          handleConfirmed(){
            this.settingChanged();
          },
          handleCancel(){
            this.setting = deepClone(this.settingCopy);
            this.closeTemplateTip();
          },
            getTemplateSetting:function(){
                getTemplateSetting(this.fileId).then(res=>{
                    if (res.data.code === 0) {
                        this.setting = res.data.data
                    }
                });
            },
            settingChanged: function () {
              this.$message.info('保存中');
                if (this.saver) {
                    clearTimeout(this.saver);
                }
                this.saver = setTimeout(() => {
                    saveTemplateSetting(this.setting).then(res => {
                      this.settingCopy = deepClone(this.setting);
                      this.closeTemplateTip();
                      this.$message.success('提示标题、内容保存成功');
                      console.log('saveTemplateSetting  res === ', res);
                        this.$emit('setting-changed', this.setting);
                    }).catch(error=>{
                        console.error(error);
                        this.$message.error('提示标题、内容保存失败');
                    });
                }, 1000);
            },
        },
        components: {DialogCloseBtn, Mask}
    }
</script>
<style scoped>
.dq-tt-title{
  font-size: 1.25rem;
  margin-top: 0;
}
.template-answer-sheet-basic-setting >>> .el-form-item__label{
  font-weight: bolder;
  line-height: 1.5;
}
.tasbs-prompt{
  text-align: right;
  margin-top: 15px;
}
.prompt-item{
  display: inline-block;
  width: 80px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  user-select: none;
  cursor: pointer;
}
.tasbs-confirm{
  background: var(--lightBlue);
  color:#fff;
  box-sizing: content-box;
  border: 1px solid var(--lightBlue);
}
.tasbs-confirm:hover{
  background: var(--darkBlue)
}
.tasbs-cancel{
  box-sizing: content-box;
  border: 1px solid #ccc;
  margin-right: 15px;
}
.tasbs-cancel:hover{
  border-color: var(--lightBlue);
  color: var(--lightBlue);
  background-color:#ecf5ff;
}
.tasbs-content-tip{
  margin:0 0 10px 70px;
  font-size: 12px;
  text-align: left;
  color: var(--font-gray);
}
</style>
