<template>
  <div class="empty-editor-placeholder">
    <h2 class="eep-title">{{info.title}}</h2>
    <div class="eep-content">
      <p class="eep-content-p">请单击页面，从空文本起草模板</p>
      <p class="eep-content-p">起草完成后，您可以：</p>
      <p class="eep-content-p">选择一段文本以填空变量替换</p>
      <p class="eep-content-p">采用变量控制一段文本的显示与隐藏</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyEditorPlaceholder",
  props:{
    info:{
      required:true
    }
  }
}
</script>

<style scoped>
.empty-editor-placeholder{
  z-index: 1;
  padding: 100px;
  position: absolute;
  color:var(--gray-2);
  user-select: none;
  pointer-events: none;
  font-weight: bolder;
}
.eep-title{
  font-size: 16pt;
  text-align: left;
}
.eep-content{
  font-size: 12pt;
  text-align: left;
}
.eep-content-p{

}
</style>
